import React from 'react'
import styled from 'styled-components'
import LayoutCenter from "parsley-core/src/components/Layout/LayoutCenter";
import { BgImage } from 'gbimage-bridge'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import MdxProvider from "parsley-core/src/components/MdxProvider/MdxProvider"
import Grid from "parsley-core/src/components/Grid/Grid"
import ContentDetail from "parsley-core/src/components/ContentDetail/ContentDetail"
import {ButtonLinkPrimary} from "parsley-core/src/components/Button/ButtonLink";

const About = props => {

	const aboutImage = getImage(props.bgImage)

	return (
		<BgImage className={props.className} image={aboutImage} backgroundColor="var(--color-tertiary-gradient-lr)">
			<LayoutCenter>
				<Grid gridGap="var(--s5)" className="grid-2">
					<ContentDetail>
						<H2Style>{props.content.frontmatter.title}<span className="h4">FRACS, Breast surg ANZ, B Med</span></H2Style>
						<MdxProvider>{props.content.body}</MdxProvider>
						<ButtonLinkPrimary to="/about" color="var(--color-text-light)">Learn more about Dr Beck</ButtonLinkPrimary>
					</ContentDetail>
					<GatsbyImageStyle alt="Photo of Dr Matthew Beck" image={props.image} />
				</Grid>
			</LayoutCenter>
		</BgImage>
	)
}

const AboutStyle = styled(About)`
  padding-top: var(--s5);
  padding-bottom: var(--s5);
  color: var(--color-text-dark);
  box-shadow: var(--box-shadow-horizontal);


`

const GatsbyImageStyle = styled(GatsbyImage)`
  border:var(--layout-space-support) solid var(--color-background-light-primary);
  @supports (border:var(--layout-space) solid var(--color-background-light-primary)) {
    border:var(--layout-space) solid var(--color-background-light-primary);
  }
	background:var(--color-background-light-primary);
`

const H2Style = styled.h2`
	padding-bottom:var(--s3);
	margin-bottom:var(--s1);
  	position:relative;
  
	&:after {
	  position:absolute;
	  bottom:0;
	  left:0;
	  width:10ch;
	  height:var(--s-5);
	  background:var(--color-primary);
	  content: ' '
	}
  
  	& span {
	  	display:block;
	    padding-top:var(--s-4);
	}
`

export default AboutStyle