import React from 'react'
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

const GoogleMap = props => {

	const ModelsMap = (map, maps) => {
		let dataArray = [];
		{
			props.data.map((markerJson) => dataArray.push(markerJson));
		}

		for (let i = 0; i < dataArray.length; i++) {
			let marker = new maps.Marker({
				position: { lat: dataArray[i].center.lat, lng: dataArray[i].center.lng },
				map
			});
		}
	}

	return (
		<div className={props.className}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: props.apiKey }}
				defaultCenter={ props.center }
				defaultZoom={props.zoom}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
			/>
		</div>
	)
}

const GoogleMapStyle = styled(GoogleMap)`
  	border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-tertiary)')};
	width:100%;
	height: ${props => (props.height ? props.height : '50vh')};
	background: ${props => (props.background ? props.background : 'none')};
`

export default GoogleMapStyle