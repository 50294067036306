import React from 'react'
import styled from 'styled-components'

const GeneralSurgeryIcon = props => {

	return (
		<svg viewBox="0 0 680 680" focusable="false" height="680px" width="680px" aria-labelledby={props.id} className={props.className} role={props.role} aria-hidden={props.ariaHidden}>
			<title id={props.id}>{props.title ? props.title : 'General surgery icon'}</title>
			<path d="M181.9,83.5l44.7,187.8l6.3-10.5c0.5-0.9,1.4-1.6,2.4-2.1l37.1-15.3L214.7,8.8l-5.9,3.7c-22.3,14.2-31.6,38.7-26.9,70.7
		L181.9,83.5z"/>
			<path d="M480,498.8c-16.3,0-32,4.7-45.4,13.7c-0.1,0.1-0.3,0.2-0.4,0.2l-0.5,0.3l-0.4,0.4c-0.5,0.4-1.1,0.8-1.7,1
		c-0.5,0.2-1,0.3-1.6,0.3c-2.2,0-4.1-1.4-4.7-3.4l-63.8-192.3l-5.8,7c-0.6,0.7-1.3,1.2-2.1,1.5l-40.2,15.1l66.5,192h-28.7
		c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10h33c0.9,0,1.7-0.1,2.5-0.3l11.8,34.2c0.1,0.3,0.2,0.7,0.2,1.1c4.7,41,39.6,72,81.2,72
		c45,0,81.7-36.4,81.7-81.2C561.7,535.2,525.1,498.8,480,498.8z M480,628.8c-27,0-49-21.9-49-48.8c0-26.9,22-48.8,49-48.8
		s49,21.9,49,48.8C529.1,606.9,507.1,628.8,480,628.8z"/>
			<path d="M403.6,83.5l0-0.2c4.7-32-4.6-56.5-26.9-70.7l-5.9-3.8l-54.4,225c-0.4,1.5-1.5,2.8-3,3.4l-72.3,29.8l-81,244.1
		c-0.7,2-2.6,3.4-4.7,3.4c-0.5,0-1.1-0.1-1.6-0.3c-0.6-0.2-1.2-0.5-1.7-1l-0.4-0.4l-0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.2
		c-13.4-9-29.2-13.7-45.4-13.7c-45,0-81.7,36.4-81.7,81.2c0,44.8,36.6,81.2,81.7,81.2c41.6,0,76.5-31,81.2-72c0-0.4,0.1-0.7,0.2-1.1
		l11.6-33.9c0.3,0,0.6,0,0.9,0h37.5c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10h-31.5l63.2-183.9c0.5-1.4,1.6-2.5,3-3.1l76-28.5
		L403.6,83.5z M105.5,628.8c-27,0-49-21.9-49-48.8c0-26.9,22-48.8,49-48.8c27,0,49,21.9,49,48.8
		C154.5,606.9,132.5,628.8,105.5,628.8z M294.4,313.1c-11.2,0-20.3-9-20.3-20.2c0-11.2,9.1-20.2,20.3-20.2c11.2,0,20.3,9,20.3,20.2
		C314.7,304.1,305.6,313.1,294.4,313.1z"/>
			<path d="M611.7,184.4c0.2,0.6,0.3,1.2,0.3,1.8v42.3h37.3v-218l-6.1,1.4c-8.9,2-16.6,6.8-22.9,14.1c-25.8,30.2-23.1,98-18,129.6
		C603.2,161.6,609.3,178.5,611.7,184.4z"/>
			<path d="M590.9,238.5v408.9c0,13.2,10.8,24,24,24h18.2c13.2,0,24-10.8,24-24V238.5H590.9z M639.4,631.6c-0.1,5.5-4.5,9.9-10,9.9
		c0,0-0.1,0-0.1,0c-5.5-0.1-9.9-4.6-9.9-10.1l3.1-229.2c0.1-5.5,4.5-9.9,10-9.9c0,0,0.1,0,0.1,0c5.5,0.1,9.9,4.6,9.9,10.1
		L639.4,631.6z"/>
		</svg>
	)
}

const GeneralSurgeryIconStyle = styled(GeneralSurgeryIcon)`
	fill: ${props => (props.fill ? props.fill : 'currentColor')};
	width: ${props => (props.width ? props.width : 'var(--s0)')};
	height: ${props => (props.height ? props.height : 'var(--s0)')};
`

export default GeneralSurgeryIconStyle