import React from "react"
import {graphql} from "gatsby"
import About from "../components/Home/About"
import Services from "../components/Home/Services"
import Location from "../components/Home/Location";
import LayoutHome from "../components/Layout/LayoutHome";

const Home = ({ data }) => {
	const pageTitle = 'Dr Matthew Beck'
	const introductionContent = data.introductionContent
	const contentBgImage = data.contentImage.childImageSharp.gatsbyImageData
	const aboutMattBeckImage = data.mattBeck.childImageSharp.gatsbyImageData
	const locationContent = data.locationContent

	return (
		<LayoutHome title={pageTitle}>
			<About content={introductionContent} bgImage={contentBgImage} image={aboutMattBeckImage} />
			<Services />
			<Location content={locationContent} />
		</LayoutHome>
	)
}

export default Home

export const pageQuery = graphql`  
  query {
  introductionContent: mdx(fileAbsolutePath: {regex: "/introduction.mdx/"}) {
    frontmatter {
      image
      title
    }
    body
  }
  contentImage: file(relativePath: {eq: "content-start.jpg"}) {
	id
	childImageSharp {
	  gatsbyImageData(
		breakpoints: [400, 700, 1000, 1300, 1600, 2000]
		layout: FULL_WIDTH
		quality: 35
	  )
	}
  }
  mattBeck: file(relativePath: {eq: "matt-beck.jpg"}) {
	id
	childImageSharp {
	  gatsbyImageData(
		breakpoints: [400, 528]
		layout: CONSTRAINED
		quality: 60
		width: 528
	  )
	}
  }
  aboutContent: mdx(fileAbsolutePath: {regex: "/about.mdx/"}) {
    frontmatter {
      image
      title
    }
    body
  }
  servicesContent: mdx(fileAbsolutePath: {regex: "/services.mdx/"}) {
    frontmatter {
      image
      title
    }
    body
  }
  informationContent: mdx(fileAbsolutePath: {regex: "/information.mdx/"}) {
    frontmatter {
      image
      title
    }
    body
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [400, 532]
			layout: CONSTRAINED
			quality: 60
			formats: JPG
		)
      }
    }
  }
  locationContent: mdx(fileAbsolutePath: {regex: "/location.mdx/"}) {
    frontmatter {
      image
      title
    }
    body
  }
}
`