import React from 'react'
import styled from 'styled-components'

const BreastIcon = props => {

	return (
		<svg viewBox="0 0 512 512" focusable="false" height="512px" width="512px" aria-labelledby={props.id} className={props.className} role={props.role} aria-hidden={props.ariaHidden}>
			<title id={props.id}>{props.title ? props.title : 'Breast ribbon icon'}</title>
			<polygon points="284.061 113.244 328.659 36 183.337 36 227.935 113.244 284.061 113.244"/>
			<path d="M392.9759,99.3906l-1.8613-3.2226-26.6992-46.24A27.8146,27.8146,0,0,0,354.221,39.7324H354.22L348.097,50.3359l-36.32,62.9082-41.92,72.6094L314.4544,263.1l79.0391-136.9023A27.8189,27.8189,0,0,0,392.9759,99.3906Z"/>
			<polygon points="124.64 437.377 191.536 476 242.148 388.34 197.552 311.088 124.64 437.377"/>
			<path d="M147.5833,49.9277l-26.6972,46.24-1.8614,3.2226a27.8219,27.8219,0,0,0-.5195,26.8067L320.4642,476,387.36,437.377,157.7786,39.7324A27.8251,27.8251,0,0,0,147.5833,49.9277Z"/>
		</svg>
	)
}

const BreastIconStyle = styled(BreastIcon)`
	fill: ${props => (props.fill ? props.fill : 'currentColor')};
	width: ${props => (props.width ? props.width : 'var(--s0)')};
	height: ${props => (props.height ? props.height : 'var(--s0)')};
`

export default BreastIconStyle
