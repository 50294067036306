import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const ButtonLink = props => {

    return (
        <Link to={props.to} className={props.className}>
            {props.children}
        </Link>
    )
}

const ButtonLinkBase = styled(ButtonLink)`
    display:inline-flex;
    font-size:var(--s0);
    padding:var(--s-3) var(--s2);
    text-decoration:none;
    font-family: inherit;
    align-items: center;
    justify-content: center;
    align-self: start;
    border-radius: var(--border-radius);
    min-width: 10ch;
    text-align: center;
    max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
    transition: ${props => (props.transition ? props.transition : '220ms all ease-in-out')};
    cursor: pointer;

    &.full-width {
        align-self:stretch;
    }

    @media screen and (-ms-high-contrast: active) {
        border: var(--border-thinnest) solid currentcolor;
    }
`

export const ButtonLinkPrimary = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-primary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-primary-border)')};
        color: ${props => (props.color ? props.color : 'var(--color-text-light)')};
    }

    & :hover {
        background-color: var(--color-primary-background-hover);
        border: var(--border-thin) solid var(--color-primary-border-hover);
    }

    & :active {
        background-color: var(--color-primary-background-active);
        border: var(--border-thin) solid var(--color-primary-border);
    }
`

export const ButtonLinkSecondary = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-secondary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-secondary-border)')};
        color: ${props => (props.color ? props.color : 'var(--color-text-light)')};
    }

    & :hover {
        background-color: var(--color-secondary-background-hover);
        border: var(--border-thin) solid var(--color-secondary-border-hover);
    }

    & :active {
        background-color: var(--color-secondary-background-active);
        border: var(--border-thin) solid var(--color-secondary-background);
    }
`

export const ButtonLinkTertiary = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-tertiary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-tertiary-border)')};
        color: ${props => (props.color ? props.color : 'var(--color-text-light)')};

    }

    & :hover {
        border: var(--border-thin) solid var(--color-tertiary-border-hover);
        background:var(--color-tertiary-background-hover);
    }

    & :active {
        border: var(--border-thin) solid var(--color-tertiary-border-active);
        background:var(--color-tertiary-background-active);
    }

`

export const ButtonLinkPrimaryInverse = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-background-light-primary);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-primary-background)')};
        color: var(--color-primary);
    }

    & :hover {
        background-color: var(--color-background-light-secondary);
        border: var(--border-thin) solid var(--color-primary-border-hover);
        //box-shadow: var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }

    & :active {
        border: var(--border-thin) solid var(--color-primary-border);
        //box-shadow: inset var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }
`

export const ButtonLinkSecondaryInverse = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-background-light-primary);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-secondary-background)')};
        color: var(--color-secondary);
    }

    & :hover {
        background-color: var(--color-background-light-secondary);
        border: var(--border-thin) solid var(--color-primary-border-hover);
        //box-shadow: var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }

    & :active {
        border: var(--border-thin) solid var(--color-seondary-border);
        //box-shadow: inset var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }
`

export const ButtonLinkTertiaryInverse = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-background-light-primary);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-tertiary-background)')};
        color: var(--color-tertiary);
    }

    & :hover {
        background-color: var(--color-background-light-secondary);
        border: var(--border-thin) solid var(--color-tertiary-border-hover);
        //box-shadow: var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }

    & :active {
        border: var(--border-thin) solid var(--color-tertiary-border);
        //box-shadow: inset var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }
    
`

export const ButtonLinkIcon = styled(ButtonLinkBase)`
    && {
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        min-width: 0;
    }
`

export const ButtonLinkIconPrimary = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-primary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-primary-border)')};
        color: ${props => (props.color ? props.color : 'var(--color-text-light)')};
        box-shadow: none;
        padding: var(--s-3) var(--s0);
        min-width: 0;
        max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
    }
`

export const ButtonLinkIconSecondary = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-secondary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-secondary-border)')};
        color: ${props => (props.color ? props.color : 'var(--color-text-light)')};
        box-shadow: none;
        padding: var(--s-3) var(--s0);
        min-width: 0;
        max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
    }
`

export const ButtonLinkIconTertiary = styled(ButtonLinkBase)`
    && {
        background-color: var(--color-tertiary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-tertiary-border)')};
        color: ${props => (props.color ? props.color : 'var(--color-text-light)')};
        box-shadow: none;
        padding: var(--s-3) var(--s0);
        min-width: 0;
        max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
    }
`