import React from 'react'
import styled from 'styled-components'
import Stack from "parsley-core/src/components/Stack/Stack";

const ServicesCard = props => {

	return (
		<Stack as="li" className={props.className}>
			<strong className="h3">{props.paragraph}</strong>
			{props.children}
		</Stack>
	)
}

const ServicesCardStyle = styled(ServicesCard)`
  padding:var(--s2) var(--s1) var(--s1);
  box-shadow: var(--box-shadow);
  height:100%;
  flex-direction: column;
  align-items: center;
  background:var(--color-background-light-primary);
  
  
  strong {
    margin:var(--s2) 0 var(--s-2);
	color:var(--color-text-dark-alt);
    justify-content:center;
  }
  
  > svg {
    order: -1;
	margin:0;
  }
  p {
	margin-top:auto;
	text-align: left;
  }
`

export default ServicesCardStyle