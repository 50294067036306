import React from 'react'
import styled from 'styled-components'
import LayoutCenter from "parsley-core/src/components/Layout/LayoutCenter"
import MdxProvider from "parsley-core/src/components/MdxProvider/MdxProvider";
import ContentDetail from "parsley-core/src/components/ContentDetail/ContentDetail";
import Grid from "parsley-core/src/components/Grid/Grid";
import GoogleMap from "parsley-core/src/components/GoogleMap/GoogleMap";
import Box from "parsley-core/src/components/Box/Box";

const Location = props => {

	const mapValues = [{
		apiKey: 'AIzaSyD35Vl57K5K38a4bgGE84uT6u42LD_cUR8',
		center: {
			lat: -34.4264393,
			lng: 150.8844592
		},
		zoom: 19,
	}]

	return (
		<div className={props.className}>
			<LayoutCenter>
				<div>
					<GridStyle gridGap="var(--s5)" className="grid-2">
						<ContentDetail>
							<H2Style>{props.content.frontmatter.title}</H2Style>
							<MdxProvider>{props.content.body}</MdxProvider>
						</ContentDetail>
						<Box border="var(--layout-space) solid var(--color-background-light-primary)"
							 background="var(--color-background-light-primary)">
							<GoogleMap
								apiKey={ mapValues[0].apiKey }
								center={ mapValues[0].center }
								zoom={mapValues[0].zoom}
								data={mapValues}
								height="50vh"
								border="var(--border-thinnest) solid var(--color-border-light)"
								background="var(--color-background-light-primary)"
							/>
						</Box>
					</GridStyle>
				</div>
			</LayoutCenter>
		</div>

	)
}

const LocationStyle = styled(Location)`
  background:var(--color-secondary-background) linear-gradient(90deg, var(--color-secondary-background) 0%, var(--color-background-light-tertiary) 100%);
  padding-top: var(--s5);
  padding-bottom: var(--s5);
  color: var(--color-text-dark);

`

const H2Style = styled.h2`
	padding-bottom:var(--s3);
	margin-bottom:var(--s1);
  	position:relative;
  
	&:after {
	  position:absolute;
	  bottom:0;
	  left:0;
	  width:10ch;
	  height:var(--s-5);
	  background:var(--color-primary);
	  content: ' '
	}
`

const GridStyle = styled(Grid)`
	align-items:center;
`

export default LocationStyle