import React from 'react'
import styled from 'styled-components'
import LayoutCenter from "parsley-core/src/components/Layout/LayoutCenter"
import Grid from "parsley-core/src/components/Grid/Grid"
import ServicesCard from "../Card/ServicesCard"
import BreastIcon from "../Icons/BreastIcon"
import SkinCancerIcon from "../Icons/SkinCancerIcon"
import GeneralSurgeryIcon from "../Icons/GeneralSurgeryIcon"
import { ButtonLinkTertiary } from "parsley-core/src/components/Button/ButtonLink"

const Services = props => (
	<div className={props.className}>
		<LayoutCenter>
			<h2>Services</h2>
			<GridStyle as="ul" gridGap="var(--s5)">
				<ServicesCard paragraph="Breast cancer and reconstructive surgery">
					<BreastIcon fill="var(--color-primary)" width="calc(4*var(--s0))" height="calc(4*var(--s0))" ariaHidden />
				</ServicesCard>
				<ServicesCard paragraph="Melanoma and skin cancer surgery">
					<SkinCancerIcon fill="var(--color-primary)" width="calc(4*var(--s0))" height="calc(4*var(--s0))" ariaHidden />
				</ServicesCard>
				<ServicesCard paragraph="General surgery and endocrine">
					<GeneralSurgeryIcon fill="var(--color-primary)" width="calc(4*var(--s0))" height="calc(4*var(--s0))" ariaHidden />
				</ServicesCard>
			</GridStyle>
			<ButtonLinkTertiary to="/services" color="var(--color-text-dark)">Learn more about Dr Beck's services</ButtonLinkTertiary>
		</LayoutCenter>
	</div>
)

const ServicesStyle = styled(Services)`
    padding:var(--s5) 0;
    background: var(--color-background-light-secondary);
  	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2384d2f6' fill-opacity='0.2'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  	& > * {
	  background:none;
	}
  
	h2 {
	  margin-bottom: var(--s1);
	}
`
const GridStyle = styled(Grid)`
	margin-bottom:var(--s4);
`

export default ServicesStyle