import React from "react"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Seo from "../Seo/Seo"

const LayoutHome = props => {

	return (
		<div className={props.className}>
			<Seo />
			<Header />
			<main id="main-content" tabIndex="-1">
				{props.children}
			</main>
			<Footer />
		</div>
	)
}

export default LayoutHome